import { graphql } from 'gatsby'
import React from 'react'

import thumbnailImg18 from '../../assets/blog/18/thumbnail.jpg'
import thumbnailImg19 from '../../assets/blog/19/thumbnail.jpg'
import thumbnailImg20 from '../../assets/blog/20/thumbnail.jpg'
import thumbnailImg21 from '../../assets/blog/21/thumbnail.jpg'
import thumbnailImg22 from '../../assets/blog/22/thumbnail.jpg'
import thumbnailImg23 from '../../assets/blog/23/thumbnail.jpg'
import thumbnailImg24 from '../../assets/blog/24/thumbnail.jpg'
import thumbnailImg1 from '../../assets/blog/thumbnail-1.png'
import thumbnailImg10 from '../../assets/blog/thumbnail-10.jpg'
import thumbnailImg11 from '../../assets/blog/thumbnail-11.jpg'
import thumbnailImg12 from '../../assets/blog/thumbnail-12.png'
import thumbnailImg13 from '../../assets/blog/thumbnail-13.png'
import thumbnailImg14 from '../../assets/blog/thumbnail-14.jpg'
import thumbnailImg15 from '../../assets/blog/thumbnail-15.jpg'
import thumbnailImg16 from '../../assets/blog/thumbnail-16.jpg'
import thumbnailImg17 from '../../assets/blog/thumbnail-17.jpg'
import thumbnailImg2 from '../../assets/blog/thumbnail-2.png'
import thumbnailImg3 from '../../assets/blog/thumbnail-3.png'
import thumbnailImg4 from '../../assets/blog/thumbnail-4.jpg'
import thumbnailImg5 from '../../assets/blog/thumbnail-5.jpg'
import thumbnailImg6 from '../../assets/blog/thumbnail-6.jpg'
import thumbnailImg7 from '../../assets/blog/thumbnail-7.jpg'
import thumbnailImg8 from '../../assets/blog/thumbnail-8.png'
import thumbnailImg9 from '../../assets/blog/thumbnail-9.jpg'
import '../../styles/global.css'
import { BlogItem } from '../../components/blog/blog-item'
import { ContentMain } from '../../components/common/content-main'
import { ContentSubPage } from '../../components/common/content-sub-page'
import { HeaderTitle } from '../../components/common/header-title'
import { CookiesBox } from '../../components/cookies-box'
import { Footer } from '../../components/footer'
import { Navigation } from '../../components/navigation'
import { SEO } from '../../components/seo'

const Blog = () => (
  <>
    <SEO title="Blog" />
    <div className="flex flex-col h-screen justify-between">
      <Navigation />
      <ContentMain>
        <HeaderTitle title="Blog" />
        <ContentSubPage>
          <BlogItem
            link="/blog/pet-kroku-k-lepsi-efektivite"
            text="Každý den máme k dispozici stejný počet hodin, ale ne každý den jsme stejně produktivní. Co dělat, abychom svůj čas využili co nejlépe?"
            thumbnail={thumbnailImg24}
            title="Pět kroků k lepší efektivitě"
          />
          <BlogItem
            link="/blog/jak-jednoduse-zorganizovat-rodinny-zivot"
            text="Život, obzvláště početnější rodiny, se může zdát jako nekonečný kolotoč povinností, úkolů a aktivit. Škola, školka, kroužky, návštěvy lékaře, nákupy, úklid, pracovní povinnosti a společenské akce - to vše vyžaduje spoustu času, energie a především dobré organizace."
            thumbnail={thumbnailImg23}
            title="Jak jednoduše zorganizovat rodinný život"
          />
          <BlogItem
            link="/blog/tridte-ukoly-podle-odhadovaneho-casu"
            text='Všichni to známe. Ty momenty během dne, kdy čekáme ve frontě, v&nbsp;zácpě na dálnici, u lékaře nebo na veřejnou dopravu. Často se stává, že tyto chvíle necháme nevyužité, protože si myslíme, že na "opravdovou" práci je čas příliš krátký. Jak ale dokázat tento čas proměnit ve skutečně produktivní okamžiky?'
            thumbnail={thumbnailImg22}
            title="Třiďte úkoly podle odhadovaného času"
          />
          <BlogItem
            link="/blog/jak-flagis-usnadnuje-spravu-ukolu-a-komunikaci"
            text="V každodenním pracovním shonu jsme často zahlceni obrovským množstvím informací. Najít konkrétní e-mail s&nbsp;tou správnou přílohou nebo informací se v&nbsp;této elektronické záplavě stává skutečným dobrodružstvím a zabírá neúměrně mnoho času – času, který bychom mohli využít mnohem efektivněji."
            thumbnail={thumbnailImg21}
            title="Jak Flagis usnadňuje správu úkolů a&nbsp;komunikaci"
          />
          <BlogItem
            link="/blog/jak-si-v-pohode-uzit-prosinec"
            text="Prosinec je tu a s ním ta nejvíce kouzelná, ale zároveň i nejhektičtější doba roku. Chcete-li se vyhnout vánočnímu šílenství a užít si Mikuláše, Vánoce i Silvestra naplno, je třeba mít věci pod kontrolou. A všichni víme, že je toho opravdu hodně!"
            thumbnail={thumbnailImg20}
            title="Jak si v pohodě užít prosinec"
          />
          <BlogItem
            link="/blog/proc-potrebujeme-system"
            text="Všichni to známe: ztracený lístek s nákupním seznamem, samolepicí papírky padající z monitoru, diáře, které běda ztratit, organizační aplikace, co leží na hřbitově ikonek ve vašem mobilu."
            thumbnail={thumbnailImg19}
            title="Proč potřebujeme systém?"
          />
          <BlogItem
            link="/blog/kdo-si-zvladne-zorganizovat-cas-vyhrava"
            text="Naši dávní předkové jistě neměli lehký život. Dennodenně čelili bezprostřednímu nebezpečí a co si neulovili, to neměli."
            thumbnail={thumbnailImg18}
            title="Kdo si zvládne zorganizovat čas, vyhrává"
          />
          <BlogItem
            link="/blog/bajecna-studentska-leta"
            text="Jaký jste typ studenta? Máte svůj život pod kontrolou?"
            thumbnail={thumbnailImg17}
            title="Báječná studentská léta"
          />
          <BlogItem
            link="/blog/jak-prezit-stavbu-domu"
            text="Lidé se prý nejvíc rozvádějí kvůli stavbě či rekonstrukci, říká lidová moudrost. Jen ten, kdo tímto procesem prošel, ví, jakou vztahovou zkouškou umí takový zážitek být."
            thumbnail={thumbnailImg16}
            title="Jak přežít stavbu domu"
          />
          <BlogItem
            link="/blog/cena-kterou-platime-za-chaoticke-nakupovani"
            text="Je pravda, že nejen chlebem živ je člověk, ale i ten příslovečný chléb umí dát jednomu docela zabrat. Zvlášť, pokud se stará o materiální potřeby celé rodiny."
            thumbnail={thumbnailImg15}
            title="Cena, kterou platíme za chaotické nakupování"
          />
          <BlogItem
            link="/blog/vyhledavani-a-filtrovani-ukolu"
            text="Jak rychle vyhledat konkrétní úkol? Jak používat filtry? To vám ukáže Filip Bartek, moderátor, muzikant, kreativec a zkušený uživatel aplikace Flagis."
            thumbnail={thumbnailImg14}
            title="Vyhledání a filtrování úkolů"
          />
          <BlogItem
            link="/blog/pet-duvodu-proc-se-vlastne-netesite-na-dovolenou"
            text="V práci plná hlava starostí, výčitky, že netrávím dost času s rodinou, žádný volný čas na čtení a sport. To všechno vyřeší krásná dovolená zalitá sluncem, pobyt mimo všední realitu."
            thumbnail={thumbnailImg13}
            title="Pět důvodů, proč se vlastně netěšíte na dovolenou"
          />
          <BlogItem
            link="/blog/kontakty-a-spoluprace-s-ostatnimi"
            text="Jak spolupracovat s ostatními v rámci aplikace Flagis? Jak si přidávat kontakty a posílat úkoly? Je to snadné a efektivní."
            thumbnail={thumbnailImg12}
            title="Kontakty a spolupráce s ostatními"
          />
          <BlogItem
            link="/blog/proc-jsou-zeny-porad-ustvane"
            text="Co znamená výraz &#8222;druhá směna&#8220; dnes asi všichni víme. Poté, co se pracující matka vrátí ze zaměstnání, případně zakončí pracovní den na home office, čeká ji práce v domácnosti a s dětmi."
            thumbnail={thumbnailImg11}
            title="Proč jsou ženy pořád uštvané?"
          />
          <BlogItem
            link="/blog/pridavani-ukolu-a-stitku"
            text="Jak vytvořit nový štítek nebo přidat úkol? Je to snadné! Práci s úkoly v aplikaci Flagis vám ukáže muzikant, moderátor a kreativec Filip Bartek."
            thumbnail={thumbnailImg10}
            title="Přidávání úkolů a štítků"
          />
          <BlogItem
            link="/blog/spasi-nas-kultura"
            text="Vytržení z každodenního shonu, chvilka k zastavení, odpočinku, načerpání inspirace nebo nových zážitků. To pro mnohé z nás představuje výlet za kulturou."
            thumbnail={thumbnailImg9}
            title="Spasí nás kultura?"
          />
          <BlogItem
            link="/blog/nastaveni-uzivatelskeho-prostredi-aplikace"
            text="Jak si nastavit váš Flagis účet? Jak přidat fotku či avatara, kalendář, změnit heslo či barevný motiv? Je to snadné! Základním nastavením v aplikaci Flagis vás provede Filip Bartek."
            thumbnail={thumbnailImg8}
            title="Nastavení uživatelského prostředí aplikace"
          />
          <BlogItem
            link="/blog/kdo-slavi-konec-roku-v-breznu"
            text="Pokud vám se začátkem března na mysli tane spíše daňové přiznání než probouzející se příroda, jste tady správně. Nejspíš totiž budete spadat do kategorie odvážlivců označovaných „přitažlivým“ názvem OSVČ ..."
            thumbnail={thumbnailImg7}
            title="Kdo slaví konec roku v březnu?"
          />
          <BlogItem
            link="/blog/samolepici-papirky-diar-nebo-chytra-appka"
            text="Píšete si úkoly a poznámky na různé papírky či do diáře a pak je nemůžete najít? Vyzkoušejte aplikaci Flagis nejen jako úkolovník, ale také jako nástroj pro správu poznámek a mějte tak vše důležité na jednom místě ..."
            thumbnail={thumbnailImg6}
            title="Samolepicí papírky, diář nebo chytrá appka?"
          />
          <BlogItem
            link="/blog/nekdy-neni-dobre-hasit-jen-to-co-hori"
            text="Začátek roku je doba, kdy víc myslíme na své zdraví. Nový rok znamená restart a všichni přece chceme žít aspoň trochu lépe. Vedle suchého února nebo předsevzetí stran pravidelného sportu a makrobiotické stravy si mnozí také vzpomenou na preventivní prohlídky ..."
            thumbnail={thumbnailImg5}
            title="Někdy není dobré hasit jen to, co hoří"
          />
          <BlogItem
            link="/blog/pet-mytu-o-novorocnich-predsevzetich"
            text="Jsou umělá a proto nefungují. Na první pohled to tak může vypadat. Zničehonic si říct: od zítřka začínám s jógou, třídím odpad a učím se holandsky. Ale ruku na srdce, ..."
            thumbnail={thumbnailImg4}
            title="Pět mýtů o novoročních předsevzetích"
          />
          <BlogItem
            link="/blog/jak-to-vsechno-zvlada"
            text="Filip Bartek, podnikavý a mnohostranně talentovaný člověk, moderátor/voiceover Hitrádia Zlín, kreativec a živnostník (výrobce dřevěných stavebnic Stouni), muzikant (frontmen skupiny Nohow), ale také aktivní tatínek a spisovatel, ..."
            thumbnail={thumbnailImg2}
            title="Jak to všechno zvládá?"
          />
          <BlogItem
            link="/blog/stastne-vesele-a-dobre-organizovane"
            text="Tak co, jak se těšíte na nadcházející svátky? Rozzáří se vám při takové otázce oči jako malým dětem? Je vám to jedno? Nebo ve vás takový dotaz vyvolá lehký mikrozáchvat paniky? Pokud jste žena, nota bene matka malých dětí, je dost pravděpodobné ..."
            thumbnail={thumbnailImg3}
            title="Šťastné, veselé a dobře organizované"
          />
          <BlogItem
            link="/blog/jak-si-nejlepe-naplanovat-a-uzit-dovolenou"
            text="Léto, sluníčko, prázdniny, moře…zima, hory, lyže, teplý grog…Taky se už těšíte na ty báječné chvíle odpočinku daleko od každodenních starostí? Kdo by se netěšil. Ale ten stres před tím! Plánování, přípravy, organizace, balení…hlavně na nic důležitého ..."
            thumbnail={thumbnailImg1}
            title="Jak si nejlépe naplánovat a užít dovolenou"
          />
        </ContentSubPage>
      </ContentMain>
      <Footer />
    </div>
    <CookiesBox />
  </>
)

export default Blog

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

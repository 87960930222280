import PropTypes from 'prop-types'
import React from 'react'

import { Link } from '../common/link'

const BlogItem = ({ link, text, thumbnail, title }) => (
  <div className="grid grid-cols-12 py-8">
    <div className="col-span-3 flex items-center">
      <img
        className="object-contain rounded-[20px]"
        src={thumbnail}
        alt="thumbnail"
      />
    </div>
    <div className="col-start-5 col-span-8">
      <Link
        className="block text-2xl font-bold pt-1 pb-3 uppercase text-black hover:text-blue hover:no-underline"
        to={link}
      >
        {title}
      </Link>
      <p>{text}</p>
      <Link className="font-bold underline" to={link}>
        zobrazit více
      </Link>
    </div>
  </div>
)

BlogItem.propTypes = {
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export { BlogItem }
